@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(0 0 0 / 83%);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(20px);
  animation: show 100ms 1;
}

.center {
  width: 360px;
  background: #141414;
  padding: 40px;
  border-radius: 16px;
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  img {
    width: 300px;
  }
}

.about {
  text-align: center;
  color: #747474;
  font-size: 12px;

  > div {
    margin: 3px 0;
  }
}

.copy {
  margin-top: 80px;
  text-align: right;
  color: #747474;

  > div {
    margin: 10px 0;
  }

  a {
    text-decoration: none;
    color: #4285f4;
  }
}

.heart {
  color: #a20220;
}
