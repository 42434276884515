body {
  font-size: 16px;
  background: #3b3b3b;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  overflow: hidden;
  padding: 0;
  margin: 0;
}

body, button, select, input {
  font-family: "FuturaPTBook", Roboto, Helvetica, Arial, sans-serif;
}

.S {
  scrollbar-width: thin;
  scrollbar-color: #535353 #2f2f2f;
}

.S::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.S::-webkit-scrollbar-thumb {
  background-color: rgba(38, 38, 38, 1);
}

.S::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
