.list {
  padding-right: 28px;
  display: flex;
  flex-grow: 1;

  ul, li {
    display: block;
    padding: 0;
    margin: 0;
  }

  ul {
    margin: 0 50px;
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
}

.list .item {
  cursor: pointer;
  margin: 4px 0;

  .icon {
    margin: auto;
    margin-bottom: 6px;
    width: 48px;
    height: 48px;
    border: 1px solid #404040;
    box-shadow: 1px 2px 6px rgb(0 0 0 / 20%);
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    box-sizing: border-box;

    img {
      height: 30px;
    }

    .rag {
      transform: scale(0.75);
    }

    .chalk {
      transform: scale(1.65);
    }
  }

  &.selected .icon {
    box-shadow: 1px 2px 6px rgb(0 0 0 / 27%), 0 0 0 2px #4285f4;
  }

  .about {
    .title {
      text-transform: uppercase;
      color: #838383;
      font-size: 9px;
      text-align: center;
    }
  }


}
