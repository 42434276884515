.button {
  position: fixed;
  top: 13px;
  left: 13px;
  background: #E9E9E9;
  border-radius: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  color: #3b3b3b;

  .circle {
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 14px;
    }
  }

  .text {
    font-size: 10px;
    padding: 0 15px;
  }
}
