.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: #3b3b3b;

  > div {
    text-align: center;
    text-transform: uppercase;
    font-family: "FuturaPTBold", Roboto, Helvetica, Arial, sans-serif;
    color: #666;
  }
}
