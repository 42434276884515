/* Color */
$colorDarkWarm: #151413;
$color: #262626;
$colorDarkGrey: #2d2d2d;
$background: #2f2f2f;
$colorMainBg: #3b3b3b;
$colorLightGrey: #e7e7e7;
$colorWhite: #fff;
$colorLightYellow: #faf8e7;
$colorXX: #8f8f8f;
$colorBlueCta: #4285f4;

/////////////////////
$colorBlack: #000000;
$colorDarkOne: #151413;
$colorDarkTwo: #262626;
$colorDarkThree: #2d2d2d;
$colorDarkFour: #2f2f2f;
$colorDarkSix: #323232;
$colorDarkFive: #3b3b3b;
$colorDarkSeven: #4e4e4e;
$colorDarkEight: #585858;

$colorLightOne: #fff;
$colorLightTwo: #faf8e7;
$colorLightThree: #e7e7e7;
$colorLightFour: #8f8f8f;
$colorLightFive: #666666;

$colorTransparent: rgba(255, 255, 255, 0);

/* Font */
$fontFamily: 'Roboto', sans-serif;
$fontSize: 16px;
$smallFontSize: 10px;

/* Transition */
$megaFastTransition: 50ms;
$superFastTransition: 100ms;
$fastTransition: 150ms;
$normalTransition: 200ms;
$slowTransition: 300ms;
$superSlowTransition: 400ms;
$megaSlowTransition: 700ms;

/* Breakpoint */
$breakpointMobile: 480px;

/* Easing */
$easeOutBack: cubic-bezier(0.34, 1.56, 0.64, 1);

/* Shadows */
$iconShadow: 2px 2px 4px rgba(0, 0, 0, 0.15);

/* Values */
$infinityRadius: 1000px;

/* Sizes */
$markerWidth: 11px;
$markerHeight: 25px;


$iconSize: 60px;
$historyCircleSize: 40px;
$rangeSize: 36px;
$colorMedium: #fbf9eb;
$backgroundWidth: 120px;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.medium {
  position: absolute;
  top: 33px;
  left: 50px;

  .gradient {
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: -1;
  }

  .gradientArc {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    div {
      position: absolute;
      background-repeat: no-repeat;
      background-size: $backgroundWidth;
      background-image: url('../assets/spriteGradient.png');
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .fadeIn {
      animation: fadeIn $normalTransition 1;
    }

    .fadeOut {
      animation: fadeOut $normalTransition 1;
      opacity: 0;
    }
  }

  .gradientHandler {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    pointer-events: all;
    -webkit-tap-highlight-color: $colorTransparent;
    outline: none;
  }
}

.handler {
  cursor: move;
}

.preview {
  position: relative;
  width: $iconSize;
  height: $iconSize;
  pointer-events: none;
  box-shadow: $iconShadow;
  border-radius: $infinityRadius;

  .impastoColor {
    position: absolute;
    top: 0;
    height: $iconSize / 2;
    left: 0;
    width: $iconSize;
    border-radius: $infinityRadius $infinityRadius 0 0;
  }

  .mediumColor {
    position: absolute;
    bottom: 0;
    height: $iconSize / 2;
    left: 0;
    width: $iconSize;
    background: $colorMedium;
    border-radius: 0 0 $infinityRadius $infinityRadius;
  }

  .currentColor {
    position: absolute;
    top: 6px;
    height: 48px;
    left: 6px;
    width: 48px;
    border-radius: $infinityRadius;
    transition: background-color $normalTransition;

    box-shadow: rgb(0 0 0 / 20%) 0px 0px  01px inset;

    //&::before {
    //  content: '';
    //  display: block;
    //  position: absolute;
    //  height: 0;
    //  top: 50%;
    //  left: 5px;
    //  right: 5px;
    //}
  }

  &:before {
    content: '';
    position: absolute;
    background: $colorMedium;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border-radius: 100px;
  }
}

@keyframes animActive {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
  }

  80% {
    transform: translate(-50%, -50%) scale(1.1);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes animInactive {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  80% {
    transform: translate(-50%, -50%) scale(0.3);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.5);
  }
}

.range {
  position: absolute;
  width: $rangeSize;
  height: $rangeSize;
  background: $colorLightOne;
  border-radius: $infinityRadius;
  cursor: move;
  transform: translate(-50%, -50%) scale(0.5);
  box-shadow: $iconShadow;

  &.active {
    animation: animActive $superFastTransition 1;
    transform: translate(-50%, -50%) scale(1);
  }

  &.inactive {
    animation: animInactive $superFastTransition 1;
  }
}

.stretchPreview {
  position: relative;
  transition: transform $fastTransition;

  .stretchCurrentColor {
    position: absolute;
    top: 6px;
    left: 30px;
    width: 24px;
    height: 48px;
    border-radius: 0 $infinityRadius $infinityRadius 0;
  }

  .historyCircle {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 24px;
    height: 48px;
    border-radius: $infinityRadius 0 0 $infinityRadius;
  }

  .saveCircle {
    position: absolute;
    top: 7px;
    left: 7px;
    bottom: 7px;
    right: 7px;
    border-radius: $infinityRadius;
    z-index: -1;
  }

  .targetCircle {
    position: absolute;
    top: 25px;
    left: 25px;
    width: 10px;
    height: 10px;
    border-radius: $infinityRadius;
    background: $colorMedium;
  }
}