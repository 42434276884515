.container {
  display: flex;
  align-items: center;
  padding-right: 28px;
}

.upgrade {
  width: 198px;
  margin: 0 50px;

  .limited {
    font-size: 26px;
    color: white;
    text-transform: uppercase;
    font-family: "FuturaPTBold", Roboto, Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 24px;
    margin-bottom: 40px;

    span {
      color: #8f8f8f;
    }
  }

  .text {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 18px;
    color: #b9b9b9;
    text-align: center;
    height: 120px;
    display: flex;
    align-items: center;

    > div {
      width: 100%;
    }

    strong {
      color: rgb(88, 149, 255);
    }

    .basic {
      color: white;
    }
  }

  .buttons {
    padding: 0 20px;
    margin-top: 40px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .oneLine {
    justify-content: end;
  }

  .premium {
    width: 100%;
    background: rgb(88, 149, 255);
    border: none;
    border-radius: 10px;
    font-size: 12px;
    color: white;
    text-transform: uppercase;
    padding: 7px 0;
    text-shadow: rgb(0 0 0 / 25%) 1px 1px 1px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;

    &:disabled {
      background: #3c3c3c;
      color: #606060;
    }
  }
}

.thanks {
  text-transform: uppercase;
  font-size: 10px;
  line-height: 18px;
  color: #b9b9b9;
  text-align: center;
  display: flex;
  align-items: center;
  margin-top: 70px;

  > div {
    width: 100%;
  }

  strong {
    color: rgb(88, 149, 255);
  }

  .basic {
    color: white;
  }

  .team {
    display: block;
    color: #a27512;
    text-align: right;
    padding-right: 12px;
  }
}
