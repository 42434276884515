$iconSize: 60px;

.container {
  pointer-events: none;
  width: $iconSize;
  height: $iconSize;
  position: fixed;

  & canvas {
    pointer-events: none;
    transform: translate(-50%, -50%);
  }
}

.preview {
  position: absolute;
  width: $iconSize;
  height: $iconSize;
  transition: opacity 150ms;
}
