.palette {
  display: flex;
}

.list {
  display: flex;
  margin-left: 35px;
}

.premium {
  padding-right: 28px;
  margin-right: 50px;
}

.dragDrop {
  display: flex;
  align-items: center;
}

.basic {
  :global(.drag-drop-elements) {
    width: 100px;
  }
}