.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menu {
  position: absolute;
  top: calc(50% + 340px);
  right: 72px;
  background: black;
  padding: 5px 0;
  border-radius: 18px;
  width: 180px;
  align-items: center;
  animation: fadeIn 200ms 1;
  transform: translateY(-225px);

  &::after {
    content: "";
    display: block;
    height: 25px;
    width: 11px;
    position: absolute;
    background: url('./assets/arrow.svg');
    bottom: 14px;
    right: -11px;
  }
}

.item {
  cursor: pointer;
  display: flex;
  padding: 0 20px;
  align-items: center;
  border-bottom: 1px solid #222222;
  height: 30px;

  &:last-child {
    border: none;
  }

  .description {
    text-transform: uppercase;
    font-size: 9px;
    color: #6c6c6c;
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
    width: 20px;

    img {
      height: 20px;
      opacity: 0.4;
    }
  }
}

.clear {
  transform: scale(0.9);
}

.save {
  transform: scale(0.8);
}

.exit {
  transform: scale(0.8);
}

