@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.button {
  position: fixed;
  bottom: 13px;
  right: 13px;
  width: 40px;
  height: 40px;
  background: #262626;
  border-radius: 100px;
  border: none;

  img {
    width: 16px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;
  animation: show 200ms 1;
}

.info {
  position: absolute;
  display: flex;
  align-items: center;

  .title {
    color: white;
    font-weight: bold;
    margin: 0 20px;
    font-size: 13px;
  }

  .description {
    color: #a7a7a7;
    font-size: 10px;
  }

  &:after {
    width: 4px;
    height: 4px;
    content: '';
    border-radius: 100px;
    background: white;
    position: absolute;
    top: 7px;
  }

  &:before {
    height: 1px;
    content: '';
    border-top: 1px solid white;
    position: absolute;
    top: 8px;
  }
}

.activeColors {
  top: 161px;
  left: 265px;

  &:after {
    top: 52px;
    left: -140px;
  }

  &:before {
    left: -138px;
    width: 145px;
    border-left: 1px solid white;
    height: 43px;
  }
}

.dilutionIndicator {
  top: 207px;
  left: 265px;

  &:after {
    left: -75px;
  }

  &:before {
    left: -74px;
    width: 82px;
  }
}

.brushColor {
  top: 253px;
  left: 265px;

  &:after {
    left: -86px;
  }

  &:before {
    left: -83px;
    width: 91px;
  }
}

.menuTool {
  position: fixed;
  top: 50%;
  height: 430px;
  width: 1px;
  right: 54px;
  transform: translateY(-50%);

  .info {
    right: 100px;
    width: 500px;
    justify-content: end;

    &:after {
      right: -98px;
    }

    &:before {
      width: 84px;
      right: -96px;
    }
  }
}

.company {
  transform: translateY(calc(-50% + 7px));

  .menu {
    display: none;
  }
}

.chalks {
  top: 96px;
}

.hogs {
  top: 139px;
}

.sobols {
  top: 183px;
}

.rugs {
  top: 228px;
}

.colorsPalette {
  top: 280px;
}

.dryingTool {
  top: 324px;
}

.menu {
  top: 396px;
}

.helper {
  bottom: 23px;
  right: 151px;

  &:after {
    right: -98px;
  }

  &:before {
    width: 84px;
    right: -96px;
  }
}

.helper, .menu {
  .title {
    margin: 0;
  }
}

.close {
  position: fixed;
  bottom: 20px;
  border: 1px solid #919191;
  border-radius: 100px;
  padding: 8px 40px;
  background: #ffffff36;
  left: 50%;
  transform: translateX(-50%);
  font-size: 11px;
  color: white;
  text-transform: uppercase;
  box-shadow: 0 0 10px #0000004d;
}