.wrapper {
  width: 134px;
  height: 130px;
  position: fixed;
}

@keyframes showUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.paint {
  width: 28px;
  height: 28px;
  position: relative;
  margin-bottom: 5px;
  transform: translate(12px, 16px);

  .splat {
    width: 100%;
    height: 100%;
    background: url('./assets/spritePaint.png');
    background-size: 38px;
    border-radius: 100px;
  }

  .border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    box-sizing: border-box;
    border: 3px solid transparent;
    display: none;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 3%);
    animation: showUp 200ms 1;
  }

  &.selected .border {
    display: block;
  }
}
