@keyframes showup {
  from {
    opacity: 0;
    transform: translate(100px, -50%);
  }
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .drawer {
    background: rgba(47, 47, 47, .94);
    position: absolute;
    height: 600px;
    border-radius: 50px;
    backdrop-filter: blur(10px);
    box-shadow: 0 3px 10px #00000024;
    top: 50%;
    transform: translate(0, -50%);
    right: 30px;
    animation: showup 300ms 1;
    display: flex;
    pointer-events: all;
  }
}

.noClick {
  pointer-events: none;
  z-index: 2;
}
