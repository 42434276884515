.ready {
  position: fixed;
  top: 13px;
  right: 13px;
  background: #a27512;
  padding: 0 24px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  color: white;
  font-family: "FuturaPTBold", Roboto, Helvetica, Arial, sans-serif;
  font-size: 12px;
  border: none;
  margin: 0;
  cursor: pointer;
}
