@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.loadingSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
}

.loader {
  width: 40px;
  height: 40px;
  background: #464646;
  border-radius: 100px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: linear-gradient(to right, #959595 0%, #959595 50%, #545454 50%, #545454 100%) #545454;
    border-radius: 100px;
    position: absolute;
    top: 10px;
    left: 10px;

    animation: rotating 500ms linear infinite;
  }
}
