.targets {
  width: 118px;
  border-right: 1px solid #3c3c3c;
  margin-right: 20px;
  display: flex;
  align-items: center;
  height: 370px;

  .targetList {
    position: relative;
    padding: 11px;
    border-radius: 100px;
    border: 1px solid #444444;
    margin-left: 5px;

    .target {
      width: 48px;
      height: 48px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-sizing: border-box;
      background-image: url('./assets/spritePaint.png');
      background-size: 48px;
      box-shadow: 1px 2px 6px rgb(0 0 0 / 27%);
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:before {
      content: '';
      width: 48px;
      height: 48px;
      position: absolute;
      top: -60px;
      left: 12px;
      background: url('./assets/dragdrop.svg') no-repeat;
    }
  }

}

.elements {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  padding: 0;
  margin: 0;
  margin-bottom: -30px;

  .element {
    width: 100px;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
    display: block;
  }

  .elemIcon {
    width: 48px;
    height: 48px;
    margin: auto;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;

    .iconBorder {
      position: absolute;
      top: 1px;
      left: 1px;
      border: 1px dashed #686868;
      width: 46px;
      height: 46px;
      box-sizing: border-box;
      border-radius: 100px;
    }
  }

  .name {
    width: 80%;
    text-align: center;
    margin: auto;
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 8px;
    color: #8f8f8f;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.itemImage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: move;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  transition: transform 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('./assets/spritePaint.png');
  background-size: 48px;

  img {
    display: block;
    max-height: 60%;
    pointer-events: none;
  }
}

.dragDropTarget {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  position: relative;
  margin-bottom: 15px;

  &:last-child {
    margin: 0;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    background-image: url('./assets/spritePaint.png');
    background-size: 48px;
  }

  &:after {
    display: block;
    content: '';
    width: 52px;
    height: 52px;
    border-radius: 100px;
    transition: transform 20ms;
    position: absolute;
    top: -2px;
    left: -2px;
    border: 1px dashed #686868;
    box-sizing: border-box;
  }
}
