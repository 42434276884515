.dragged {
  opacity: 0;
  pointer-events: none;
}

@keyframes makeSizeSmaller {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

@keyframes makeSizeRegular {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

$easeOutBack: cubic-bezier(0.34, 1.56, 0.64, 1);

.dragHandler {
  animation-duration: 150ms;
  animation-iteration-count: 1;
  animation-name: makeSizeSmaller;
  animation-timing-function: $easeOutBack;
  position: fixed;
  transform: scale(0.8);
  z-index: 99999 !important;
  transition: transform 150ms $easeOutBack;

  &.normal {
    transform: scale(1);
  }

  &.touch {
    pointer-events: none;
  }

  &.click {
    pointer-events: all;
  }
}

.withShowUpAnimation {
  animation-duration: 150ms;
  animation-iteration-count: 1;
  animation-name: makeSizeRegular;
  animation-timing-function: $easeOutBack;
}

.active {
  :global(.drag-drop-target:after) {
    transform: scale(1.1);
  }
}
