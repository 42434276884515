.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #262626;
  z-index: 999;
}

.center {
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.content {
  position: relative;
  margin-top: 210px;

  h2 {
    padding: 0;
    margin: 0;
    color: white;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
  }

  .text {
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    color: #626262;
    padding: 0 50px;
  }

  .or {
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    color: #626262;
    margin-bottom: 20px;
  }

  .link {
    border: 1px solid #3e3e3e;
    background: #161616;
    padding: 8px 20px;
    border-radius: 50px;
    color: white;
    font-size: 12px;
    -webkit-user-select: all; /* Chrome 49+ */
    -moz-user-select: all; /* Firefox 43+ */
    -ms-user-select: all; /* No support yet */
    user-select: all;
    width: 50%;
    margin: auto;
  }

  .back {
    text-align: center;
    margin-top: 30px;

    a {
      color: white;
    }
  }
}

.qrcode {
  margin: 20px 0;
  display: flex;
  justify-content: center;

  canvas {
    border: 10px solid #262626;
  }
}
