.container {
  position: fixed;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  width: 50px;
  z-index: 3;

  .menu {
    background: #262626;
    border-radius: 100px;
    box-sizing: border-box;
    box-shadow: 0 5px 20px rgb(0 0 0 / 44%);
    padding: 20px 0;

    ul {
      display: block;
      padding: 40px 0;
      margin: 0;
    }

    li {
      display: block;
      padding: 0;
      margin: 4px 0;
    }

    .icon {
      width: 40px;
      height: 40px;
      border-radius: 12px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      transition: background-color 200ms;

      img {
        height: 30px;
      }

      .chalkImg {
        transform: scale(1.65);
      }

      .ragImg {
        transform: scale(0.75);
      }
    }

    .separator {
      width: 12px;
      height: 3px;
      background: #323232;
      margin: 5px auto;
      border-radius: 100px;
    }

    .palette, .time {
      img {
        height: unset;
        width: 20px;
      }
    }

    .time {
      position: relative;

      .indicator {
        position: absolute;
        top: 15px;
        left: 13px;
        width: 14px;
        height: 14px;
        border-radius: 100px;
        transition: transform 600ms;

        &::after {
          content: '';
          display: block;
          width: 2px;
          height: 6px;
          background: #666;
          position: absolute;
          border-radius: 100px;
          left: 6px;
        }
      }
    }

    .activeTool .icon {
      background: #1c1c1c;
    }

    .open .icon {
      background: #2f2f2f !important;
    }
  }
}

.logo {
  display: flex;
  justify-content: center;

  img {
    width: 28px;
  }
}

.optionsButton {
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    width: 30px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 14px;
      opacity: 0.35;
    }
  }
}
