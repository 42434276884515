.page {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .center {
    width: 460px;
    text-align: center;

    img {
      width: 260px;
    }

    h1 {
      font-size: 22px;
      color: #626262;
      padding: 0 20px;
      font-family: "FuturaPTBold", Roboto, Helvetica, Arial, sans-serif;
    }

    h2 {
      font-size: 14px;
      color: #202020;
      margin-bottom: 60px;
    }

    a {
      padding: 10px;
      text-decoration: none;
      border-radius: 100px;
      background: #006bff;
      color: white;
      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}
