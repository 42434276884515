@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  backdrop-filter: blur(20px);
  animation: show 100ms 1;

  .center {
    background: white;
    border-radius: 13px;
    width: 300px;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }

  .text {
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    font-family: "FuturaPTBold", Roboto, Helvetica, Arial, sans-serif;
    margin-bottom: 20px;
    color: #404040;
  }

  .answers {
    button {
      display: block;
      width: 100%;
      padding: 13px 0;
      border-radius: 13px;
      border: none;
      font-size: 12px;
      color: #191202;
      text-transform: uppercase;
    }

    .yes {
      background: #BE8508;
      margin-bottom: 10px;
    }

    .no {
      background: #C4C3C4;
    }
  }
}
