@keyframes fadeIn1 {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn2 {
  from {
    opacity: 0;
    transform: translateY(-60px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn3 {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3b3b3b;
  pointer-events: none;

  .content {
    width: 700px;
    height: 500px;
    position: relative;
    margin-top: -50px;
  }

  .image {
    position: absolute;
    display: flex;

    img {
      display: block;
      width: 300px;
      border-radius: 10px;
      box-shadow: 0 5px 20px rgb(0 0 0 / 44%);
      animation: fadeIn1 300ms 1;
    }
  }

  .header {
    position: absolute;
    top: 20px;
    left: 200px;
    font-size: 60px;
    text-transform: uppercase;
    font-family: "FuturaPTBold", Roboto, Helvetica, Arial, sans-serif;
    text-shadow: 0 0 3px 4px black;
    color: white;
    animation: fadeIn2 400ms 1;
  }

  .description {
    font-size: 12px;
    position: absolute;
    right: 0;
    width: 300px;
    top: 200px;
    text-align: justify;
    line-height: 20px;
    color: #727272;
    text-indent: 30px;
    animation: fadeIn3 500ms 1;

    strong {
      color: #4285f4;
    }
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 300px;
    height: 3px;
    background: #262626;
    transform: translateX(-50%);
    border-radius: 10px;
    overflow: hidden;
    border: 5px solid #262626;
    box-shadow: 0 5px 20px rgb(0 0 0 / 44%);

    .indicator {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: #4c4c4c;
      border-radius: 0 100px 100px 0;
      width: 0;
      transition: width 5s;
    }

    .completed {
      transition: width 300ms !important;
    }
  }
}

.continue {
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 300px;
  transform: translateX(-50%);
  text-align: center;

  button {
    background: #4285f4;
    border: none;
    color: white;
    border-radius: 100px;
    padding: 10px;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-size: 16px;
    pointer-events: auto;

    &:disabled {
      background: #4a4a4a;
      color: #818181;
    }
  }

  .auto {
    font-size: 10px;
    color: #7e7e7e;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    margin: auto;
    border-radius: 6px;
    pointer-events: auto;

    span {
      margin-left: 3px;
      display: block;

    }
  }
}

/* IMAGES */

.david {
  .image {
    min-height: 421px;
    min-width: 300px;
  }
}

.rubens {
  .image {
    min-height: 418px;
    min-width: 300px;
  }
}

.lievens {
  .header {
    top: 88px;
    left: 257px;
  }

  .image {
    min-height: 401px;
    min-width: 300px;
  }
}

.kauffmann {
  .header {
    top: -39px;
    left: 200px;
    font-size: 52px;
  }

  .image {
    min-height: 362px;
    min-width: 300px;
  }
}

.batoni {
  .header {
    left: 346px;
    top: 6px;
  }

  .image {
    min-height: 381px;
    min-width: 300px;
  }
}

.potocki {
  .image {
    right: 0;
    min-height: 455px;
    min-width: 330px;

    img {
      width: 330px;
    }
  }

  .description {
    left: 0;
    width: 270px;
    top: 230px;
  }

  .header {
    top: -11px;
    left: 0;
    font-size: 46px;
    width: 467px;
  }
}

.lastJudgment {
  .image {
    min-height: 266px;
    min-width: 400px;

    img {
      width: 400px;
    }
  }

  .header {
    width: 400px;
    top: 217px;
    left: 33px;
  }

  .description {
    width: 210px;
    top: 56px;
  }
}

.ladyErmine {
  .header {
    left: 223px;
  }

  .image {
    min-height: 403px;
    min-width: 300px;
  }
}

.gameChess {
  .image {
    right: 0;
    min-height: 227px;
    min-width: 300px;
  }

  .header {
    left: 10px;
    width: 325px;
    text-align: right;
    top: 35px;
  }

  .description {
    width: 430px;
    top: 254px;
    right: 140px;
  }
}

.pourville {
  .header {
    left: 235px;
  }

  .description {
    width: 345px;
  }

  .image {
    min-height: 242px;
    min-width: 300px;
  }
}
